'use strict';

(function($){
  var app = {

  init: function() {
    this.cookiesAccept();
    this.objectfit();
    svg4everybody();
    this.lazyframe();
    this.fileBrowser();
    this.search();
    //this.validate();
    return false;
  },
  cookiesAccept: function () {
    if ($('#cookies-popup') && $.cookie('acepta_cookies')!== 'ok') {
      //setTimeout( function() {$('#cookies-popup').animate({height:"toggle"},'fast','linear'); }, 3000);
      $('#cookie-btn').click(function(event){
        event.preventDefault();
        $('#cookies-popup').animate({height:"toggle"},'fast','linear');
        $.cookie('acepta_cookies', 'ok', { expires: 7, path: '/' });
      });
    }
  },
  lazyframe: function () {
    lazyframe('.lazyframe', {
      apikey: 'AIzaSyAmYFr0H-sXNFM6Q03PaBDaI_A1Yi9BAfo', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
      debounce: 250,

      onAppend: (function (lazyframe) {
        return console.log(lazyframe.parentNode.classList.add("loaded"))
      })
    }); 
  },
  objectfit: function () {
    // a javascript fallback for CSS 'object-fit' property for browsers that don't support it
    if ('objectFit' in document.documentElement.style === false) {
      var elements = document.querySelectorAll('.bg-image')
      elements.forEach(function(i){
        var img = i,
          src = img.src,
          parent = img.parentNode,
          allClasses = img.className,
          array = allClasses.split(" "),
          c,
          newDiv = document.createElement("div")
        
        for (c = 0;c < array.length; c++) {
          newDiv.classList.add(array[c])
        }

        newDiv.style.cssText = "background: url(" + src + ");background-size:cover;background-position:50% 50%"
        parent.insertBefore(newDiv, img)
        parent.removeChild(img)

      })
/*       $('.bg-image').each(function attachBg() {
        var img = $(this);
        var src = img.attr('src');
        var classes = img.get(0).classList; // Replaces the default <img.bg-image> element with a <div.bg-image>
        // to attach background using legacy friendly CSS rules
        console.log(classes)

        img.before($("<div class=\"" + classes + "\" style=\"background: url(" + src + "); background-size: cover; background-position: 50% 50%;\"></div>")); // Removes original <img.bg-image> as it is no longer required

        img.remove();
      }); */
    }         
  },
  validate: function() {
/*     console.log('Hello master')
    $.validate({
      lang: 'es',
      validateHiddenInputs: true,
      validateOnEvent: true    
    }); */
/*     $('.validate-top').validate({
        errorMessagePosition: 'top'
    });   */        
  },  
  fileBrowser: function() {
    var inputFiles = document.querySelectorAll('.custom-file-input')
    inputFiles.forEach(function (inputFile) {
      return inputFile.addEventListener('change', function () {
        var filepath = this.value,
        filename = filepath.split('\\').pop().split('/').pop();
        this.nextElementSibling.innerHTML = filename;
      });
    });
  },
  search: function () {
    var searchBtn = document.getElementById('btn-search'),
    searchClose = document.getElementById('search-close'),
    searchFormContainer = document.getElementById('search-js');

    searchClose.addEventListener('click', function(e) {
      searchFormContainer.classList.remove('open');
    })

    searchBtn.addEventListener('click', function(e) {
      searchFormContainer.classList.add('open');
      e.stopPropagation();
    })

    window.addEventListener('click', function(e) {
      if (e.target != searchFormContainer && e.target != $(searchFormContainer).find('input').get(0) && e.target != $(searchFormContainer).find('button').get(0) && e.target != $(searchFormContainer).find('form').get(0)) {
        searchFormContainer.classList.remove('open');
      }
    })
  },
  // Para usar ajax se debe usar otra versión que no sea la slim de jQuery xxx
  /*
  ajaxCall: function(url, type, dataType, $dataObj, cache, crossDomain) {
      // Default values if undefined values comes
      if (typeof type === 'undefined') type = 'GET';
      if (typeof dataType === 'undefined') {
          
          //    "xml": Returns a XML document that can be processed via jQuery.
          //    "html": Returns HTML as plain text; included script tags are evaluated when inserted in the DOM.
          //    "script": Evaluates the response as JavaScript and returns it as plain text. Disables caching by appending a query string parameter, _=       [TIMESTAMP], to the URL unless the cache option is set to true. Note: This will turn POSTs into GETs for remote-domain requests.
          //    "json": Evaluates the response as JSON and returns a JavaScript object. Cross-domain "json" requests are converted to "jsonp" unless          the request includes jsonp: false in its request options. The JSON data is parsed in a strict manner; any malformed JSON is                   rejected and a parse error is thrown. As of jQuery 1.9, an empty response is also rejected; the server should return a                        response of null or {} instead. (See json.org for more information on proper JSON formatting.)
          //    "jsonp": Loads in a JSON block using JSONP. Adds an extra "?callback=?" to the end of your URL to specify the callback. Disables              caching by appending a query string parameter, "_=[TIMESTAMP]", to the URL unless the cache option is set to true.
          //    "text": A plain text string.
          
          dataType = 'html';
      }
      if (typeof dataToSend === 'undefined') dataToSend = {};
      if (typeof crossDomain === 'undefined') crossDomain = false;

      // 99% percent times, is a form. By default serialize
      if (typeof $dataObj === 'object') {
          dataToSend = $dataObj.serialize();
      }

      $.ajax({
          type: 'POST',
          url: "/",
          data: { data: dataToSend },
          dataType: "html",
          cache: false,
          async: true,
          crossDomain: crossDomain,
          timeout: 5,
          xhr: function()
          {
              var xhr = new window.XMLHttpRequest();
              //Upload progress
              xhr.upload.addEventListener("progress", function(evt){
                  if (evt.lengthComputable) {
                      var percentComplete = evt.loaded / evt.total;
                      //Do something with upload progress
                  }
              }, false);
              //Download progress
              xhr.addEventListener("progress", function(evt){
                  if (evt.lengthComputable) {
                      var percentComplete = evt.loaded / evt.total;
                      //Do something with download progress
                  }
              }, false);

              return xhr;
          },
          beforeSend: function (xhr, settings) {
              // code here
          },
          complete: function (xhr, status) {
              // code here
          },
          success: function (response) {
              // code here
          },
          error : function (xhr, textStatus, errorThrown) {
              // code here
          },
      });
      return true;
  },
  */
  /*
  cleanUri: function(str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
      
      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

      return str;
  },
  */
  };
  // DOM Ready
  $(function(){
    app.init()
    //console.log('%c 2018-06-18 Ekaitz', 'color: #fff; background: #38AE52; font-size: 14px; padding: 5px 10px;');
  });
  // Resize Window
  /*
  $(window).resize( function(){

      
  });
  */
})(jQuery);